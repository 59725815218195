import { contentConstants } from '../constants';
import { ScaleSize } from 'Functions'

let user = JSON.parse(localStorage.getItem('user'));

function setStyle() {
    const scale = window.innerWidth > 1440 ? 1 : window.innerWidth /1440

    return {
        scale: scale,
        page: {
            //minHeight: window.innerHeight - document.getElementById('header').clientHeight,
            minHeight: 500,
        },
        h2: {
            fontSize: ScaleSize(40, 21, scale),
            lineHeight: ScaleSize(63, 28, scale)
        },  
        icon: {
            width: ScaleSize(53, 43, scale),
        },
        iconSmall: {
            width: ScaleSize(53, 32, scale),
        },
        iconSound: {
			width: ScaleSize(46, 25, scale)
		},
        quote: {
            width: ScaleSize(29, 15, scale)
        },
        rich: {
            fontSize: ScaleSize(1, 0.8, scale, 'em')
        }
    }
}

function setSupport() {
    const SUPPORTS_BACKDROP_FILTER = (() => {
        return typeof CSS !== 'undefined' && // CSS is not defined for IE 11
            typeof CSS.supports !== 'undefined' &&
            CSS.supports('backdrop-filter: blur(1px)');
    })();

    return {
        SUPPORTS_BACKDROP_FILTER: SUPPORTS_BACKDROP_FILTER
    }
}

const initialState = {
    language: 'de',
    galleryImages: [],	
    pages: [],
    style: setStyle(),
    support: setSupport()
}

console.log(window.innerWidth)

export function content(state = initialState, action) {
	switch (action.type) {
        case contentConstants.GETPAGE_REQUEST:
            return {
                ...state
            }
        case contentConstants.GETPAGE_SUCCESS:

            const pages = state.pages;
			const index = pages.findIndex(page => page.path === action.page.path)

			if(index>-1) {
				pages[index] = action.page
			} else {
				pages.push(action.page)
			}

            return {
                ...state,
                pages
            }
        case contentConstants.GETPAGE_FAILURE:
            return { 
                error: action.error
            }
        
        case contentConstants.RESIZE:
            return {
                ...state,
                style: setStyle()
            }

        case contentConstants.SET_GALLERY:
            return {
                ...state,
                galleryImages: action.images
            }

        case contentConstants.SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            }

        default:
            return state
    }
}