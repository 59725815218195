import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { contentActions } from 'actions'

import { withRouter, Route, Switch } from 'react-router-dom'
import { TransitionGroup, Transition, CSSTransition } from 'react-transition-group'
import './App.sass';

//import ContentStore from './stores/ContentStore'
//import ContentStoreActions from './actions/ContentStoreActions'
import Header from './components/header'
import Page from './components/page'
import Overlay from './components/content/overlay'
import Footer from './components/footer'
import Cookie from './components/cookie'

import TestComponent from './components/TestComponent'

/*
const transitionStyles = {
    entering: { },
    entered: { transform: 'scale(0.7)' },
    exiting: {  }
};
*/

const transitionStyles = {
    entering: { },
    entered: { },
    exiting: {  }
};

function App({location, resize, language, setLanguage}) {

    const resizeTimeout = useRef(null)

    const test = () => {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = setTimeout(resize, 100);
    }

    useEffect(() => {
        window.addEventListener('resize', test)     

		return () => {
            window.removeEventListener('resize', test)
        }
    }, [])

    useEffect(() => {
        
        let languageNew = location.pathname.split('/')[1] === 'en' ? 'en' : 'de'

        console.log(languageNew, language)

        if(languageNew!==language) {
            setLanguage(languageNew)
        }


    }, [location])

    

    /*
    setLanguage = () => {
        let language = this.props.location.pathname.split('/')[1] === 'en' ? 'en' : 'de'

        if(language!==this.state.language) {
            //ContentStoreActions.setLanguage(language)
        }

    }

    componentDidMount = () => {
        window.addEventListener('resize', this.setSizes)
        //this.setSizes()
        this.setLanguage()
    }

   

    componentDidUpdate() {
        let params = new URLSearchParams(this.props.location.search)
        //console.log('update:' + !!params.get('gallery'));

        if((params.get('gallery') || params.get('page')) && this.state.styleOverlay === null) {

            document.body.classList.add("overlay");

            this.scrollTop = window.scrollY || window.pageYOffset;

            let style = {
                react: {
                    overflow: 'hidden',
                    height: window.innerHeight
                },
                page: { 
                    marginTop: `-${window.scrollY || window.pageYOffset}px`
                }
            }

            this.setState({styleOverlay: style});

        } else if(!params.get('gallery') && !params.get('page') && this.state.styleOverlay) {

            this.setState({styleOverlay: null});
            
            document.body.classList.remove("overlay");

            setTimeout(() => window.scroll(0, this.scrollTop), 10);

        }

        this.setLanguage()

    }
    */

    let params = new URLSearchParams(location.search)
    const currentKey = location.pathname.split('?')[0] || '/'
    const timeout = { enter: 200, exit: 100 }


  
	/*
  
            <Overlay
                type="page"
                active={!!params.get('page')} 
                currentPage={params.get('page')}
                style={this.state.style}
            /> 
    */
    
    return (
        <>
            <div id="page">
                <div>
                    <Header />
                    <TransitionGroup component="div" id="content">
                        <CSSTransition key={currentKey} timeout={timeout} classNames="fade" appear>                          
                            <Switch>
                                <Route
                                    path="/:level1?/:level2?/:level3?"
                                    render={(props) => <Page {...props} />}
                                />
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <Footer />
            </div>        
            <Overlay
                type="gallery"
                active={!!params.get('gallery')} 
            />
            <Overlay
                type="page"
                active={!!params.get('page')} 
                currentPage={params.get('page')}
            />  
            <Cookie />  
        </>
    )
				
    
}

const mapStateToProps = (state, props) => {
    const { language } = state.content
    
    return {
		language
	}
}

const mapDispatchToProps = dispatch => ({
    resize: () => dispatch(contentActions.resize()),
    setLanguage: (language) => dispatch(contentActions.setLanguage(language)),  
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App))
