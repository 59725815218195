import { pdfConstants } from '../constants';

function addRemoveImage(image) {
    return (dispatch) => {
        dispatch({ 
            type: pdfConstants.ADDREMOVE_IMAGE,
            image 
        })
    } 
}

function updateAllImages(images) {
    console.log('updateAllImages')
    return (dispatch) => {
        dispatch({ 
            type: pdfConstants.UPDATEALL_IMAGES,
            images 
        })
    } 
}

export const pdfActions = {
    addRemoveImage,
    updateAllImages
}