import React from 'react'

import {withRouter, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import styles from './Cookie.module.sass'

function setCookie(cname, cvalue, mins) {
    var d = new Date();
    d.setTime(d.getTime() + (mins*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

class CookieInfo extends React.Component {
	constructor() {
		super();

        //this.stores = [ContentStore]

	    let accept = getCookie('accept') ? true : false
       
		this.state = {
            accept
        }
  	}

  	acceptCookie = () => {
  		this.setState({ accept: true});

        setCookie('accept', 1, 1440000)
  	} 

	render() {
		let style = this.props.style

		if(this.state.accept) {
			return false
		}

		return (
			<div className={styles.cookie}>
                <div className="center main">
                    <div className="inner">
                        {this.props.language==='de' ? (
                            <p>Wir verwenden auf dieser Webseite Tracking-Tools, durch die wir eine anonymisierte Übersicht über die Nutzung unserer Webseite erhalten. Mit Bestätigung willigen Sie hierin ein. Ihre Einwilligung können Sie jederzeit widerrufen. Näheres dazu in unserer <Link to="/datenschutz">Datenschutzerklärung</Link>.</p>
                        ):(
                            <p>We use tracking tools on this website in order to get an anonymous overview of its performance. Please confirm below to consent to this. You can revoke your consent at any time. More details can be found in our <Link to="/en/data-protection">privacy policy</Link>.</p>
                        )}
                        <span className="accept" onClick={this.acceptCookie}>{this.props.language==='de' ? 'Ich stimme zu' : 'I agree'}</span>
                    </div>
                </div>
            </div>
		)
	}
}

const mapStateToProps = (state, props) => {
	
	const { language } = state.content

	return {
		language
	}
}

export default withRouter(connect(
    mapStateToProps,
    null
)(CookieInfo))
