import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter} from 'react-router-dom'
import { RichTextClick, ScaleSize } from '../../Functions'

class Text extends Component {
	static contextTypes = {
        router: PropTypes.object
    }

	render() {
		let data = this.props.data,
		scale = this.props.style.scale,
		style = this.props.style,
		content = [],
		style2 = {}

		if(data.fullWidthColor) {
			style2 = {background: data.color, ...style}
		}

		if(data.noMarginTop) {
			style2 = {marginTop: 0, ...style}
		}

		for(var i=1; i<=data.cols; i++) {
			content.push(
				<div className="col" key={i}>
					<div style={{background: !data.fullWidthColor ? data.color : 'none'}} dangerouslySetInnerHTML={{__html: data[`text_${i}`]}} />
				</div>
			)
		}

		return (
			<div className={'content text clear cols' + data.cols} style={style2}>
				<div className="center main">
					<div className="rich" style={style.rich} onClick={(e) => RichTextClick(e, this.props.location, this.props.history)}>
						{content}
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Text);