import React, { Component, useState, useEffect } from 'react'
import styles from './Overlay.module.sass'
import PropTypes from 'prop-types'
import { withRouter, useLocation, useHistory } from 'react-router-dom'

import { ParamAdd, ParamBack } from '../../../Functions'

import Gallery from './Gallery'
import Page from './Page'


const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 }
};

let scrollTop = null

function Overlay({ active, type }) {
    const [visible, setVisible] = useState(false)
    const [fullscreen, setFullscreen] = useState(false)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (active && !visible) {

            scrollTop = window.scrollY || window.pageYOffset;

            let height = document.documentElement.scrollHeight;
            //console.log(height, scrollTop, window.innerHeight)

            let test = (scrollTop + window.innerHeight / 2) / height * 100
            //console.log(test)

            let styleTest = `transform-origin: center ${test}%; position: fixed; width: 100%; top: -${scrollTop}px`;

            //console.log(styleTest)

            document.getElementById('page').style.cssText = styleTest;
            document.getElementById('page').classList.add("test");

            setVisible(true)
            setFullscreen(false)
        }

        if (!active && visible) {

            document.getElementById('page').classList.remove("test");

            setTimeout(() => {
                setVisible(false)
                document.getElementById('page').style.cssText = ''
                window.scroll(0, scrollTop)
                setFullscreen(false)
            }, 500);
        }
    }, [active, visible])


    const handleClose = () => {
        setTimeout(function () {

            if (document.fullscreen) {

                setTimeout(function () {

                    var elem = document.documentElement;

                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }


                }.bind(this), 400)

            }


        }.bind(this), 400)

        history.push(location.pathname)
    }

    const handleFullScreen = () => {
        if (!document.fullscreen) {

            var elem = document.documentElement;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }

            setFullscreen(true)

        }
    }

    if (!visible) return null

    console.log('render overlay')
    console.log(document.fullscreen)

    return (
        <div className={styles.overlay + (active ? ' in' : ' out')}>
            <div className="actions">
                {(type === 'gallery' && !fullscreen) &&
                    <span className="fullscreen" onClick={() => handleFullScreen()}>Fullscreen</span>
                }
                <span className="close" onClick={() => handleClose()}></span>
            </div>
            {type === 'gallery' &&
                <Gallery />
            }
            {type === 'page' &&
                <Page />
            }

        </div>
    )
}

export default Overlay;

class Overlay2 extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)
        //this.stores = [ContentStore, PdfStore]

        this.state = {
            visible: false,
            closed: true,
        };

        this.escFunction = this.escFunction.bind(this);
    }



    handleClose = () => {
        setTimeout(function () {
            /*
            this.setState({    
                closed: true,
                currentImage: null
            });
            */

            if (document.fullscreen) {

                setTimeout(function () {

                    var elem = document.documentElement;

                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }


                }.bind(this), 400)

            }


        }.bind(this), 400)

        this.context.router.history.push(this.props.location.pathname)
    }
    /*
    componentDidMount() {
        if(this.props.active && !this.state.visible) {
            this.setState({    
                visible: true
            });
       }

    }
    */

    componentDidUpdate() {

        //console.log('overlay didupdate')
        /*
        console.log(this.state.currentImage +'!='+ this.props.currentImage)

        if(this.state.closed && !this.state.currentImage) {

            let index = this.state.gallery.findIndex(image => image.ID === parseInt(this.props.currentImage));

            console.log(index)

            if(index>-1) {

                console.log('SETED ' + index);

                this.setState({
                    closed: false, 
                    currentImage: index
                });

            }

        }

        */

        /*
       let params = new URLSearchParams(this.props.location.search)

       if((!params.get('gallery') && !params.get('page')) && !this.state.closed) {
           
            
            setTimeout(function() { 
                        
                this.setState({    
                    closed: true
                });

             }.bind(this), 400)
             
       }
       */

        if (this.props.active && !this.state.visible) {
            //console.log('set visible')
            this.setState({
                visible: true
            });
        }

        if (!this.props.active && this.state.visible) {
            setTimeout(() => {
                this.setState({
                    visible: false
                });
            }, 400);
        }
    }


    escFunction(event) {
        if (event.keyCode === 27) {
            this.handleClose()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    fullscreen = () => {

        if (!document.fullscreen) {

            var elem = document.documentElement;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }

        }

    }



    render() {

        if (!this.state.visible) return null

        return (
            <div id="overlay" className={this.props.active ? 'in' : 'out'}>
                <div className="actions">
                    {(this.props.type === 'gallery' && !document.fullscreen) &&
                        <span className="fullscreen" onClick={() => this.fullscreen()}>Fullscreen</span>
                    }
                    <span className="close" onClick={() => this.handleClose()}></span>
                </div>
                {this.props.type === 'gallery' &&
                    <Gallery
                        style={this.props.style}
                    />
                }

            </div>
        )

        /*
        {this.props.type==='page' &&
                    <Page
                        style={this.props.style}
                        currentImage={this.props.currentPage}
                    />
                }
                */

    }
}
