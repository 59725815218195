import React, { createRef, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/icon/Icon'

import { ScaleSize } from 'Functions'

import styles from './Teaser.module.sass'
import { useIsVisivible } from 'hooks'

import LazyImageContainer from 'components/LazyImageContainer'

export default function Project({data, style, onClick, index}) {
	const ref = useRef()
	const { loaded, visible } = useIsVisivible({ref})

    let scale = style.scale
	style = {...style,
		h2: {
			fontSize: ScaleSize(28, 15, scale),
			lineHeight: ScaleSize(36, 19, scale)
        },
        icon: {
            width: ScaleSize(53, 28, scale),
        }
	}

	const classes = []
	visible && classes.push('show')
	
	return (
        <div
            className={'project fadeIn fromBottom ' + classes.join(' ')} style={{backgroundColor: data.color}}
            onClick={onClick ? () => onClick() : null}
            ref={ref} 
        >
            {!onClick &&
			    <Link to={data.url} className="area"></Link>
            }
            <div className="table">
                <div className="col image">
                    <div style={{backgroundImage: `url(${data.image})`}} />
                </div>
                <div className="col text">
                    {data.logo &&
                        <img className="logo" src={data.logo} />
                    }
                    {data.text &&
                        <h2 style={style.h2} dangerouslySetInnerHTML={{__html: data.text}}></h2>
                    }
                    <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                        <Icon type="arrow" />
                    </span> 
                </div>
            </div>
		</div>
	)
}