import React, { useRef } from 'react';
import styles from './Contact.module.sass'
import { withRouter, Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { ScaleSize } from 'Functions'
import { useIsVisivible } from 'hooks'

const Contact = ({style, location, data, language}) => {
    const ref = useRef()
    const { loaded, visible } = useIsVisivible({ref})

    //const back = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

    var path = location.pathname.split("/");
    var back = path.slice(0, path.length-2).join("/");

    console.log(style)

    style = {...style,
        h4: {
            fontSize: ScaleSize(30, 20, style.scale),
            lineHeight: ScaleSize(40, 28, style.scale)
        },
        p: {
            fontSize: ScaleSize(20, 15, style.scale),
            lineHeight: ScaleSize(28, 23, style.scale)
        },
        email: {
            fontSize: ScaleSize(15, 12, style.scale),
            lineHeight: ScaleSize(53, 32, style.scale)
        }
    }

    return (
        <div className={'content ' + styles.contact }  ref={ref} >       
            <div className="center main">
                <div className={'inner fadeIn' + (visible ? ' show' : '')}>
                    <h4 style={style.h4}>{data.title}</h4>
                    <div className="table">
                        <div className="col image">
                            <div style={{backgroundImage: `url(${data.image})`}}
                            />
                        </div>
                        <div className="col text">
                            <h4 style={style.h4}>{data.title}</h4>
                            <p style={style.p} dangerouslySetInnerHTML={{__html: data.text}}>
                                
                            </p>
                            <a href={`mailto:${data.email}`} style={style.email}>
                            {language === 'de' ? 'Kontakt Aufnehmen' : 'Contact'} <span className="icon main" style={{width: style.iconSmall.width, height: style.iconSmall.width}}>
                                <Icon type="arrow" />
                            </span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Contact);