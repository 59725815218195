import React, { Component, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { pdfActions } from 'actions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
//import ContentStore from '../../../stores/ContentStore'
//import PdfStore from '../../../stores/PdfStore'
//import PdfStoreActions from '../../../actions/PdfStoreActions'
import { ParamAdd, ParamBack, useQuery } from '../../../Functions'
import Icon from '../../icon/Icon'
import { useRef } from 'react';


function Slide({index, active, data, currentImage}) {
    const [ loaded, setLoaded ] = useState(false)
    const [ fullResolution, setFullResolution ] = useState(false)

    let loading = useRef(false)

    //console.log(index)
    
    useEffect(() => {
        
        console.log(loading.current, active)

        
        if(!loading.current && active) {

            console.log('startloading')
           
            loading.current = true
            
            var i = new Image();
            i.onload = () => {
                setLoaded(true)
            };
            
            i.src = data.url;
        }
        

    }, [active])

    useEffect(() => {
        if(index===currentImage) {
            setTimeout(() => {
                if(index===currentImage) setFullResolution(true)

            }, 500);
        } else {
            setFullResolution(false)
        }
    
    }, [currentImage])

    let backgroundImage = ''
    if(active && !loaded) backgroundImage = `url(${data.thumbnailUrl})`
    if(active && loaded) backgroundImage = `url(${data.thumbnailUrl})` 
   

    return <li style={{ backgroundImage: backgroundImage, left: `${index * 100}%` }}>
        {loaded && fullResolution &&
            <div style={{backgroundImage: `url(${data.url})`}} />
        }
    </li>
}


function Gallery({ style, galleryImages, location, history, pdfImages, addRemoveImage }) {
    const [currentImage, setCurrentImage] = useState(null)
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    const [showInfo, setShowInfo] = useState(null);

    //const query = useQuery()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const gallery = parseInt(params.get('gallery'))

        //console.log(gallery)
        //console.log(galleryImages)

        let index = galleryImages.findIndex(image => image.ID === gallery);

        if (index > -1) {

            console.log('SETED ' + index);

            setCurrentImage(index)

            //this.props.setClosed(false)
        }

    }, [galleryImages])

    const showSlide = (direction) => {
        let currentImageNew = direction === 'previous' ? currentImage - 1 : currentImage + 1
        if (currentImageNew < 0) currentImageNew = galleryImages.length - 1
        if (currentImageNew > galleryImages.length - 1) currentImageNew = 0

        setCurrentImage(currentImageNew)

        let image = galleryImages[currentImageNew]

        history.push({ ...location, search: `?gallery=${image.ID}` })

        //console.log(currentImage)
    }

    const addImage = () => {
        let image = galleryImages[currentImage];

        addRemoveImage(image)
    }

    const handleTouchStart = (e) => {
        /* this.touchXstart = e.touches[0].clientX;
        console.log(e.touches[0].clientX)
        */

        console.log('handleTouchStart');
        setTouching({
            ...touching,
            touchXstart: e.touches[0].clientX
        })
    }

    const handleTouchMove = (e) => {
        let touchX = e.touches[0].clientX - touching.touchXstart;

        console.log('diff:' + touchX)

        //console.log(e.touches[0].clientX)

        if (Math.abs(touchX) > 10) {
            setTouching({
                ...touching,
                touching: true,
                touchX
            })

            /*this.setState({
                touching: true,
                touchX: touchX 
            });
            */
        }
    }

    const handleTouchEnd = (e) => {
        console.log(e.changedTouches[0].clientX)
        console.log('handleTouchEnd');

        if (touching.isTouching) {

            setTouching({
                ...touching,
                touching: false
            })

            if (touching.touchX > 0) {
                showSlide('previous')
            } else {
                showSlide('next')
            }

        }
    }



    const content = useMemo(
        () => {

            console.log('gallery images calculate')

            const test = galleryImages.map((image, i) => {

                const active = (i >= currentImage - 1 && i <= currentImage + 1) ? true : false

                return <Slide key={i} index={i} active={active} data={image} currentImage={currentImage} />
            })

            return test

        },
        [currentImage]
    );

     
    /*
        content = galleryImages.map((data, idx) => {

            if (currentImage === null) {
                return false
            }

            let left = (idx === currentImage) ? 0 : (idx < currentImage) ? '-100' : '100';

            if (idx === currentImage && touching.isTouching) {
                left = (touching.touchX / window.innerWidth) * 100
            }

            if (idx === currentImage) {
                pdf = galleryImages.findIndex(entry => entry.ID === data.ID) > -1 ? true : false
            }

            let backgroundImage = ''

            if (idx >= currentImage - 1 && idx <= currentImage + 1) {
                backgroundImage = `url(${data.thumbnailUrl})`
            }

            if ((data.vimeo_url || data.iframe_url) && idx === currentImage) {
                return <li key={idx} style={{ left: `${left}%` }}>
                    <iframe
                        src={data.vimeo_url ? data.vimeo_url + '?autoplay=true' : data.iframe_url}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                    >
                    </iframe>
                </li>
            } else {
                return <li key={idx} style={{ backgroundImage: backgroundImage, transform: `translateX(${left}%)` }}></li>
            }
        });
    */

    if (currentImage == null) return null

    console.log('render gallery')

    let image = galleryImages[currentImage];
    
    const pdf = pdfImages.findIndex(entry => entry.ID === image.ID) > -1 ? true : false
    const text = (image && (image.title || image.text)) ? true : false

    let translateX = currentImage * -100;

    if (touching.isTouching) {
        translateX += (touching.touchX / window.innerWidth) * 100;
    }

    return (
        <div className="gallery">
            <span className="state">{currentImage + 1}/{galleryImages.length}</span>

            <ul className={touching.isTouching ? 'touching' : null} style={{ transform: `translateX(${translateX}%)` }} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                {content}
            </ul>

            {currentImage > 0 &&
                <span className="button icon main previous" onClick={() => showSlide('previous')} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="arrow" /></span>
            }

            {currentImage < galleryImages.length - 1 &&
                <span className="button icon main next" onClick={() => showSlide('next')} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="arrow" /></span>
            }

            <div className="buttons">
                {text &&
                    <span className="icon main info" onClick={() => setShowInfo(true)} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="info" /></span>
                }
                <span
                    className={`icon main pdf ${pdf ? 'selected' : ''}`}
                    style={{ width: style.icon.width, height: style.icon.width }}
                    onClick={() => addImage()}>
                    {!pdf &&
                        <Icon type="pdf" />
                    }
                    {pdf &&
                        <Icon type="pdfActive" />
                    }
                    {pdf &&
                        <Icon type="pdfRemove" />
                    }
                </span>
            </div>
            {showInfo &&
                <div className="info">
                    <div>
                        <span className="close" onClick={() => setShowInfo(false)}></span>
                        {image && image.title &&
                            <h4>{image.title}</h4>
                        }
                        {image && image.text &&
                            <p dangerouslySetInnerHTML={{ __html: image.text }}></p>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const { style, galleryImages } = state.content
    const { pdfImages } = state.pdf

    return {
        style,
        galleryImages,
        pdfImages
    }
}

const mapDispatchToProps = dispatch => ({
    addRemoveImage: image => dispatch(pdfActions.addRemoveImage(image))
})

function propsAreEqual(prev, next) {
    console.log(prev)
    if (prev.galleryImages && prev.galleryImages !== next.galleryImages) return false
    if (prev.pdfImages && prev.pdfImages !== next.pdfImages) return false
    return true
}

const enhance = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(React.memo(Gallery, propsAreEqual));

/*
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gallery)
*/

class Gallery2 extends Component {
    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props)
        //this.stores = [ContentStore, PdfStore]

        this.state = {
            currentImage: null,
            info: false
        };

    }

    componentDidMount() {
        this.checkGallery();
    }

    componentDidUpdate() {
        this.checkGallery();
    }

    checkGallery = () => {
        console.log('Check Gallery ' + this.props.currentImage)

        if (this.state.currentImage === null) {

            let index = this.state.gallery.findIndex(image => image.ID === parseInt(this.props.currentImage));

            console.log(index)

            if (index > -1) {

                console.log('SETED ' + index);

                this.setState({
                    currentImage: index
                });

                //this.props.setClosed(false)

            }

        }
    }

    showSlide = (direction) => {

        let currentImage = this.state.currentImage

        switch (direction) {
            case 'previous':

                currentImage--

                if (currentImage < 0) {
                    currentImage = this.state.gallery.length - 1
                }

                break;

            case 'next':

                currentImage++

                if (currentImage > this.state.gallery.length - 1) {
                    currentImage = 0
                }

                break;
        }

        this.setState({
            currentImage: currentImage
        });

        let image = this.state.gallery[currentImage]

        this.context.router.history.replace(ParamAdd(this.props.location, 'gallery=' + image.ID, true))

        console.log(currentImage)
    }

    addImage = () => {
        let image = this.state.gallery[this.state.currentImage];

        console.log(image.ID);
        //PdfStoreActions.addImage(image);
    }

    handleInfo = () => {
        this.setState({
            info: !this.state.info
        });
    }

    handleTouchStart = (e) => {
        this.touchXstart = e.touches[0].clientX;
        console.log(e.touches[0].clientX)
        console.log('handleTouchStart');
    }

    handleTouchMove = (e) => {
        let touchX = e.touches[0].clientX - this.touchXstart;

        console.log('diff:' + touchX)

        //console.log(e.touches[0].clientX)

        if (Math.abs(touchX) > 10) {
            this.setState({
                touching: true,
                touchX: touchX
            });
        }
    }

    handleTouchEnd = (e) => {
        console.log(e.changedTouches[0].clientX)
        console.log('handleTouchEnd');

        if (this.state.touching) {

            this.setState({
                touching: false
            });

            if (this.state.touchX > 0) {
                this.showSlide('previous')
            } else {
                this.showSlide('next')
            }

        }
    }

    render() {
        let style = this.props.style,
            pdf,
            text,
            content = this.state.gallery.map((data, idx) => {

                if (this.state.currentImage === null) {
                    return false
                }

                //console.log(idx + '/' + this.state.currentImage)

                let left = (idx === this.state.currentImage) ? 0 : (idx < this.state.currentImage) ? '-100' : '100';

                if (idx === this.state.currentImage && this.state.touching) {
                    left = (this.state.touchX / window.innerWidth) * 100
                }

                if (idx === this.state.currentImage) {
                    pdf = this.state.images.findIndex(entry => entry.ID === data.ID) > -1 ? true : false
                }

                let backgroundImage = ''

                if (idx >= this.state.currentImage - 1 && idx <= this.state.currentImage + 1) {
                    backgroundImage = `url(${data.url})`
                }

                if ((data.vimeo_url || data.iframe_url) && idx === this.state.currentImage) {
                    return <li key={idx} style={{ left: `${left}%` }}>
                        <iframe
                            src={data.vimeo_url ? data.vimeo_url + '?autoplay=true' : data.iframe_url}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                        >
                        </iframe>
                    </li>
                } else {
                    return <li key={idx} style={{ backgroundImage: backgroundImage, left: `${left}%` }}></li>
                }
            });

        let image = this.state.gallery[this.state.currentImage];

        text = (image && (image.title || image.text)) ? true : false

        return (
            <div>
                {this.state.gallery && this.state.gallery.length > 1 &&
                    <span className="state">{this.state.currentImage + 1}/{this.state.gallery.length}</span>
                }

                <ul className={this.state.touching ? 'touching' : null} onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    {content}
                </ul>

                {this.state.currentImage > 0 &&
                    <span className="button icon main previous" onClick={() => this.showSlide('previous')} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="arrow" /></span>
                }

                {this.state.currentImage < this.state.gallery.length - 1 &&
                    <span className="button icon main next" onClick={() => this.showSlide('next')} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="arrow" /></span>
                }
                <div className="buttons">
                    {text &&
                        <span className="icon main info" onClick={this.handleInfo} style={{ width: style.icon.width, height: style.icon.width }}><Icon type="info" /></span>
                    }
                    <span
                        className={`icon main pdf ${pdf ? 'selected' : ''}`}
                        style={{ width: style.icon.width, height: style.icon.width }}
                        onClick={() => this.addImage()}>
                        {!pdf &&
                            <Icon type="pdf" />
                        }
                        {pdf &&
                            <Icon type="pdfActive" />
                        }
                        {pdf &&
                            <Icon type="pdfRemove" />
                        }
                    </span>
                </div>
                {this.state.info &&
                    <div className="info">
                        <div>
                            <span className="close" onClick={this.handleInfo}></span>
                            {image && image.title &&
                                <h4>{image.title}</h4>
                            }
                            {image && image.text &&
                                <p dangerouslySetInnerHTML={{ __html: image.text }}></p>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

//export default withRouter(Gallery);