import React from 'react'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Breadcrumb extends React.Component {
	
	constructor(props) {
 		super(props)
 	}

	click = (e) => {
		/*
		console.log( this.state.lastUrl +' === '+ e.target.getAttribute('href') );
		if(this.state.lastUrl === e.target.getAttribute('href')) {
			//e.preventDefault();
			//this.props.history.goBack();

		}
		*/
		
		
	}
	

	render() {

		let data = this.props.data,
		content

		if(data) {
			content = data.map((data, idx) => { 
				return <li key={idx}><Link onClick={this.click} to={data.url} ><span dangerouslySetInnerHTML={{__html: data.title + '<span />'}}></span></Link></li>
			});
		}

		

		return (
			<div className="navigation main breadcrumb">
				<div className="center main">
					{content &&
						<ul>
							<li><Link onClick={this.click} to={this.props.language==='de' ? '/' : '/en'}><span>Home<span></span></span></Link></li>
							{content}
						</ul>
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	const { language } = state.content

	return {
		language,
	}
}

const mapDispatchToProps = dispatch => ({

})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Breadcrumb))