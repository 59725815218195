import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import MultiGallery from './MultiGallery'

class Location extends Component {
	constructor(props) {
 		super(props);
 		
 		this.state = {
 			location: 0
        };   
 	}

 	setView = (location) => {

 		console.log(location)

 		this.setState({	
 			location: location
 		});
 	}

	render() {
		let data = this.props.data

		return (
			<div className={'content location'}>
				<MultiGallery 
					data={data}
					style={this.props.style}
					view={data.view}
				/>
			</div>
		)
	}
}

export default Location;