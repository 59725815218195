import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useLocation, useHistory, withRouter } from 'react-router-dom'

function TestComponent2({location}) {
   

    console.log('render TestComponent')
return (<div>Test Component {location.pathname}</div>)
}

function moviePropsAreEqual() {
    return true
}

export default withRouter(React.memo(TestComponent2, moviePropsAreEqual));