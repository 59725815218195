import axios from 'axios'
import { contentConstants } from '../constants';

function getPage(path) {
    const request = () => { return { type: contentConstants.GETPAGE_REQUEST } }
    const success = (page) => { 
        return { 
            type: contentConstants.GETPAGE_SUCCESS, 
            page
        } 
    }
    const failure = (error) => { return { type: contentConstants.GETPAGE_FAILURE, error } }

    return (dispatch, getState) => {
        dispatch(request());

        let data = {
            params: {
                url: path
            }
        }

        axios.get('https://www.747studios.de/wordpress_v2/loader.php', data, {headers: {}})
        .then(response => {      
            
            let page = response.data
            page.path = path

            dispatch(success(page));
        })
        .catch(function (error) {
            console.log(error)
        });

    } 
}

function resize() {
    return (dispatch) => {
        dispatch( { type: contentConstants.RESIZE } )
    } 
}

function setGallery(images) {
    return (dispatch) => {
        dispatch({ 
            type: contentConstants.SET_GALLERY,
            images 
        })
    } 
}

function setLanguage(language) {
    return (dispatch) => {
        dispatch({ 
            type: contentConstants.SET_LANGUAGE,
            language 
        })
    } 
}

export const contentActions = {
    getPage,
    resize,
    setGallery,
    setLanguage
}