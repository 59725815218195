import React, { createRef, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/icon/Icon'

import { ScaleSize } from 'Functions'

import styles from './Teaser.module.sass'
import { useIsVisivible } from 'hooks'

import LazyImageContainer from 'components/LazyImageContainer'

export default function Teaser({data, style, onClick, index}) {
	const ref = useRef()
	const { loaded, visible } = useIsVisivible({ref})

	const classes = []
	data.size && classes.push(data.size)
	!data.size && classes.push('fromBottom')
	if(index && index%2==1) classes.push('delay')
	visible && classes.push('show')
	
	let textDelay = ''
	if(index && index%2==1) textDelay = ' delay' 

	return (
        <div
            className={'teaser aniHover fadeIn ' + classes.join(' ')}
            onClick={onClick ? () => onClick() : null}
            ref={ref} 
        >
            {!onClick &&
			    <Link to={data.url} className="area"></Link>
            }
            <div className="main" style={{backgroundColor: data.color}}>
				<LazyImageContainer url={data.image} shouldLoad={loaded} withFadeIn />
				{data.logo
					? <div className={'logo textfadeIn fromBottom'+ textDelay} style={{backgroundImage: `url(${data.logo})`}} />
					: <h2 style={style.h2} className={'textfadeIn fromBottom'+ textDelay}><span dangerouslySetInnerHTML={{__html: data.title}}></span></h2>
				}
				{!data.text &&
					<span className="icon main bottomRight" style={{width: style.icon.width, height: style.icon.width}}>
						<Icon type="arrow" />
					</span>
				}
				<span className="hoverArea aniHover" />
			</div>
			{data.text &&
				<div className="text">
					<p className={'textfadeIn fromBottom' + textDelay} style={style.p}>{data.text}</p>
					<span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
						<Icon type="arrow" />
					</span>
				</div>
			}
		</div>
	)
}