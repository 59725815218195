import React, { Component } from 'react'
import { connect } from 'react-redux'
import { pdfActions } from 'actions'
//import Reflux from 'reflux'
//import PdfStore from '../../../stores/PdfStore'
//import PdfStoreActions from '../../../actions/PdfStoreActions'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from './Column'

import { ScaleSize } from '../../../Functions'

class Images extends React.Component {
	constructor(props) {
		super(props);
		//this.stores = [PdfStore]

		this.state = {
			images: [...this.props.images],
			tasks: {},
			columns: {},
			columnOrder: []
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.setSizes)
        this.setSizes();    
	}

	componentWillUnmount = () => {
    	//Reflux.Component.prototype.componentWillUnmount.call(this);

        window.removeEventListener('resize', this.setSizes)
    }

	setSizes = () => { 
		const colsCurrent = this.state.sizes && this.state.sizes.cols ? this.state.sizes.cols : null;

    	let componentWidth = this.component.clientWidth,
    	cols = this.component.clientWidth > 1000 ? 4 : this.component.clientWidth >= 720 ? 3 : this.component.clientWidth >= 310 ? 2 : 1,
		space = 0.03 *componentWidth,
		colWidth = (componentWidth - (cols-1) * space) / cols

    	this.setState({
            sizes: {
            	cols: cols,
            	space: space,
            	colWidth: colWidth,
            	iconWidth: ScaleSize(53, 43, this.props.scale),
            } 
        })

    	if(colsCurrent !== cols) {
        	const newState = this.reOrder(this.state.images, cols);
			this.setState(newState);
		}
    }

	reOrder = (images, cols) => {
		let tasks = [],
		columns = [],
		columnOrder = [],
		currentColnumber = 0

		images.forEach((image, i) => {	    
			tasks['task-'+ image.ID] = {id: 'task-'+ image.ID, ...image}

			if(i%cols===0) {
				currentColnumber++;

				let test = `column-${currentColnumber}`

				columns[test] = {
					id: test,
					title: test,
					taskIds: []
				}

				columnOrder.push(test)
			}

			let test = `column-${currentColnumber}`

			columns[test].taskIds.push('task-'+ image.ID)

			//console.log(i%4)
		});

		console.log(columns)

		const initialData = {
			images: images,
			tasks: tasks,
			columns: columns,
			columnOrder: columnOrder
		}

		return initialData;
	}

	removeImage = (image) => {
		this.props.addRemoveImage(image);

		let images = [...this.state.images]

		console.log(images)

		images = images.filter(entry => entry.ID != image.ID);

		console.log(images)

		const newState2 = this.reOrder(images, this.state.sizes.cols);

		console.log(newState2)

		this.setState(newState2);

	}

	onDragEnd = result => {
		const { destination, source, draggableId } = result;

		console.log('drag')

		if(!destination) {
			return
		}

		if(
			destination.droppableId	=== source.droppableId &&
			destination.index === source.index			
		) {
			return
		}

		

		const start = this.state.columns[source.droppableId]
		const finish = this.state.columns[destination.droppableId]

		if(start===finish) {

			const newTaskIds = Array.from(start.taskIds)
			newTaskIds.splice(source.index, 1)
			newTaskIds.splice(destination.index, 0, draggableId)

			const newColumn = {
				...start,
				taskIds: newTaskIds
			}

			const newState = {
				...this.state,
				columns: {
					...this.state.columns,
					[newColumn.id]: newColumn
				}
			}

			this.setState(newState);

			let images = []

			Object.keys(newState.columns).forEach((key,index) => {
				let column = newState.columns[key] 

				column.taskIds.forEach((task) => {
					let id = parseInt(task.slice(5));

					let image = this.state.images.filter((image) => image.ID === id)[0]

					images.push(image)
				});
			});

			this.props.updateAllImages(images)


			return 
		}

		//

		const startTaskIds = Array.from(start.taskIds);
		startTaskIds.splice(source.index, 1)
		const newStart = {
			...start,
			taskIds: startTaskIds
		}

		const finishedTaskIds = Array.from(finish.taskIds)
		finishedTaskIds.splice(destination.index, 0, draggableId);
		const newFinish = {
			...finish,
			taskIds: finishedTaskIds
		}

		const newState = {
			...this.state,
			columns: {
				...this.state.columns,
				[newStart.id]: newStart,
				[newFinish.id]: newFinish
			}
		}

		//

		let images = []

		Object.keys(newState.columns).forEach((key,index) => {
    		let column = newState.columns[key] 

    		column.taskIds.forEach((task) => {
				let id = parseInt(task.slice(5));

				let image = this.state.images.filter((image) => image.ID === id)[0]

				images.push(image)
			});
		});

		const newState2 = this.reOrder(images, this.state.sizes.cols);

		this.props.updateAllImages(images)

		this.setState(newState2);

		console.log(newState2)
	}

	render() {

		let style = {}

		if(this.state.sizes) {
			style={margin: `-${this.state.sizes.space/2}px`}

			console.log(style)
		}

		return (
			<div className="images" ref={component => this.component = component}>
				<div style={style}>
					<DragDropContext onDragEnd={this.onDragEnd}>
						{this.state.columnOrder.map(columnId => {
							const column = this.state.columns[columnId]
							const tasks = column.taskIds.map(taskId => this.state.tasks[taskId])

							return <Column key={column.id} column={column} tasks={tasks} sizes={this.state.sizes} removeImage={image => this.removeImage(image) } />
						})}
					</DragDropContext>
				</div>
			</div>
		)
	}

}

const mapStateToProps = (state, props) => {
	
	const { pdfImages } = state.pdf

	console.log(pdfImages)

	return {
		images: pdfImages
	}
}

const mapDispatchToProps = dispatch => ({
	addRemoveImage: image => dispatch(pdfActions.addRemoveImage(image)),
	updateAllImages: images => dispatch(pdfActions.updateAllImages(images)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Images)

