import React from 'react'
import { connect } from 'react-redux'

//import Reflux from 'reflux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
//import ContentStore from '../../stores/ContentStore'
//import ContentStoreActions from '../../actions/ContentStoreActions'
//import PdfStore from '../../stores/PdfStore'
//import PdfStoreActions from '../../actions/PdfStoreActions'

import Icon from '../icon/Icon'

import { ParamAdd } from '../../Functions'

class Gallery extends React.Component {
	static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
 		super(props)
 		//this.stores = [ContentStore, PdfStore]

        this.state = {
            currentImage: 0
        };  
 	}

    clickImage = () => {
        //ContentStoreActions.setGallery(this.props.data.images);

         let image = this.props.data.images[this.state.currentImage];
        
        this.context.router.history.push( ParamAdd(this.props.location, 'gallery=' + image.ID))
    }

    addImage = () => {
        let image = this.props.data.images[this.state.currentImage];

        console.log(image.ID);
        //PdfStoreActions.addImage(image);
    }


    showSlide = (currentImage) => {      
        let data = this.props.data
    	

        if(currentImage<0) {
            currentImage = data.images.length-1
        }

        if(currentImage>data.images.length-1) {
            currentImage = 0
        }

        this.setState({	
 			currentImage
 		});

 		console.log(currentImage)
    }

	render() {
        let data = this.props.data,
        style = this.props.style

        let navigation = [],
        pdf

        console.log(this.state.images);

		let content = data.images.map((image, idx) => { 

            navigation.push(
                <li key={idx}
                    className={idx === this.state.currentImage ? 'selected' : null}
                    onClick={()=>this.showSlide(idx)}>
                    <span />
                </li>
            )

            if(idx === this.state.currentImage) {
                pdf = this.props.pdfImages.findIndex(entry => entry.ID === image.ID) > -1 ? true : false 
            }

            let left = (idx === this.state.currentImage) ? 0 : (idx < this.state.currentImage) ? '-100' : '100'; 
			return <li key={idx} style={{backgroundImage: `url(${image.url})`, left: `${left}%`}} onClick={image.vimeo_url ? this.clickImage : null}>
                {image.vimeo_url &&
                    <span 
                        className="icon main lens middle aniHover"
                        style={{width: style.icon.width, height: style.icon.width}}
                        ><Icon type="play" /></span>
                }
                <p>{image.caption}</p>
            </li>
        });
        
        let params = new URLSearchParams(this.props.location.search);

        if(params.get('gallery') && !this.state.gallery.length) {		
			//ContentStoreActions.setGallery(data.images);
		}

		return (	
            <div className="content gallery" style={{background: data.color}}>
                <div className="center main">
                    <div className="inner">
                        <span className="fullscreen" onClick={() => this.clickImage()}>Fullscreen</span>
        				<div className="media clear">
                            <ul>
            					{content}
            				</ul>
                        </div>
        				{this.state.currentImage>0 &&
                            <span className="button icon main previous" style={{width: style.icon.width, height: style.icon.width}} onClick={()=>this.showSlide(this.state.currentImage-1)}><Icon type="arrow" /></span>
        				}
                        {this.state.currentImage<data.images.length-1 &&
                            <span className="button icon main next" style={{width: style.icon.width, height: style.icon.width}} onClick={()=>this.showSlide(this.state.currentImage+1)}><Icon type="arrow" /></span>
                        }
                        <ul>
                            {navigation}
                        </ul>
                        <span 
                            className={`icon main pdf ${pdf ? 'selected' : ''}`} 
                            style={{width: style.icon.width, height: style.icon.width}}
                            onClick={() => this.addImage()}>
                            {!pdf &&
                                <Icon type="pdf" />
                            }
                            {pdf &&
                                <Icon type="pdfActive" />
                            }
                            {pdf &&
                                <Icon type="pdfRemove" />
                            }
                        </span>
                    </div>
                </div>
			</div>         		
		)
	}
}

const mapStateToProps = (state, props) => {
	const { pdfImages } = state.pdf

	return {
		pdfImages
	}
}

const mapDispatchToProps = dispatch => ({

})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Gallery))