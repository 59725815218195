import React, { Component } from 'react'

const Icon = ({type}) => {
    function getIcon() {

        switch (type) {
            case 'arrow':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" stroke="#E8247E" className="stroke">
                        <g fill="none" fillRule="evenodd">
                            <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" points="28 23 28 33 38 33" transform="rotate(-135 33 28)" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M38,28 L15,28" />
                        </g>
                    </svg>

                )

            case 'pdf':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdf">
                        <g fill="none" fillRule="evenodd">
                            <path className="fill" fill="#E8247E" d="M12.755,32.16 L12.755,23.43 C12.755,22.905 13.16,22.5 13.685,22.5 L16.895,22.5 C19.355,22.5 20.885,23.895 20.885,26.01 L20.885,26.04 C20.885,28.395 18.995,29.625 16.685,29.625 L14.6,29.625 L14.6,32.16 C14.6,32.67 14.195,33.075 13.685,33.075 C13.16,33.075 12.755,32.67 12.755,32.16 Z M14.6,27.96 L16.745,27.96 C18.14,27.96 19.01,27.18 19.01,26.085 L19.01,26.055 C19.01,24.825 18.125,24.18 16.745,24.18 L14.6,24.18 L14.6,27.96 Z M23.475,32.07 L23.475,23.43 C23.475,22.905 23.88,22.5 24.405,22.5 L27.39,22.5 C30.69,22.5 32.97,24.765 32.97,27.72 L32.97,27.75 C32.97,30.705 30.69,33 27.39,33 L24.405,33 C23.88,33 23.475,32.595 23.475,32.07 Z M25.32,31.32 L27.39,31.32 C29.595,31.32 31.035,29.835 31.035,27.78 L31.035,27.75 C31.035,25.695 29.595,24.18 27.39,24.18 L25.32,24.18 L25.32,31.32 Z M35.905,32.16 L35.905,23.43 C35.905,22.905 36.31,22.5 36.835,22.5 L42.955,22.5 C43.42,22.5 43.795,22.875 43.795,23.34 C43.795,23.805 43.42,24.18 42.955,24.18 L37.75,24.18 L37.75,27.045 L42.28,27.045 C42.745,27.045 43.12,27.42 43.12,27.885 C43.12,28.35 42.745,28.725 42.28,28.725 L37.75,28.725 L37.75,32.16 C37.75,32.67 37.345,33.075 36.835,33.075 C36.31,33.075 35.905,32.67 35.905,32.16 Z" />
                        </g>
                    </svg>
                )

            case 'pdfActive':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdfActive">
                        <g fill="none" fillRule="evenodd" strokeWidth="2">
                            <polyline stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" points="22.22 35.028 33.14 35.028 33.025 13.449" transform="rotate(45 27.68 24.238)" />
                        </g>
                    </svg>
                )

            case 'pdfRemove':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51" className="pdfRemove">
                        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                            <g stroke="#FFF" strokeLinecap="round" strokeWidth="2" strokeLinejoin="round" transform="translate(18 18)">
                                <path d="M8.55781463,19.8480293 L8.44252081,-1.73072577" transform="rotate(45 8.5 9.059)" />
                                <path d="M0.830163182,1.47017226 L16.1701723,16.6471313" />
                            </g>
                        </g>
                    </svg>
                )

            case 'lens':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51">
                        <g fill="none" fillRule="evenodd" transform="rotate(-90 27.5 26.5)">
                            <g transform="rotate(-45 42.038 3.32)">
                                <path className="stroke" stroke="#E8247E" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.4120383,8.47258766 L1.0658141e-13,8.47258766" />
                                <circle className="fill" cx="19.814" cy="8.806" r="8.806" fill="#E8247E" />
                            </g>
                            <path fill="#FFF" className="fillReverse" d="M29.16,25.972 L29.16,23.748 L26.888,23.748 C26.408,23.748 26.008,23.364 26.008,22.868 C26.008,22.388 26.408,21.988 26.888,21.988 L29.16,21.988 L29.16,19.764 C29.16,19.252 29.56,18.852 30.072,18.852 C30.568,18.852 30.984,19.252 30.984,19.764 L30.984,21.988 L33.256,21.988 C33.736,21.988 34.136,22.388 34.136,22.868 C34.136,23.364 33.736,23.748 33.256,23.748 L30.984,23.748 L30.984,25.972 C30.984,26.484 30.568,26.884 30.072,26.884 C29.56,26.884 29.16,26.484 29.16,25.972 Z" />
                        </g>
                    </svg>
                )

            case 'info':
                return (
                    <svg width="55" height="55" viewBox="2 2 51 51">
                        <g>
                            <path d="M26.4,33.9v-8.4c0-0.6,0.5-1.2,1.2-1.2c0.6,0,1.2,0.5,1.2,1.2v8.4c0,0.6-0.5,1.2-1.2,1.2
                                C26.9,35.1,26.4,34.6,26.4,33.9z" fill="#E8247E" className="fill" />
                            <path fill="#E8247E" className="fill" d="M27.5,11.5c0.8,0,1.3,0.6,1.3,1.3v0c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3v0C26.2,12.1,26.8,11.5,27.5,11.5
                                    z"/>
                        </g>
                    </svg>
                )

            case 'play':
                return (
                    <svg width="55" height="55" viewBox="1 1 44 44">
                        <polygon strokeWidth="2" className="stroke fill" stroke="#E8247E" fill="none" strokeMiterlimit="10" points="18.17 14.15 18.17 30.85 29.71 22.5 18.17 14.15" />
                    </svg>
                )

            case 'soundon':
                return (
                    <svg viewBox="0 0 50 50">
                        <g>
                            <path style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} d="M24,6c-2.8,2.6-10.4,11.2-10.4,11.2l-6.4-0.1c0,0-2.1-0.5-2.2,1.9c-0.1,2.4,0,5.8,0,5.8s-0.1,3.4,0,5.8
        c0.1,2.4,2.2,1.9,2.2,1.9l6.4-0.1c0,0,7.7,8.7,10.4,11.2c2.8,2.6,3.5-0.3,3.5-0.3V24.9V6.4C27.5,6.4,26.8,3.5,24,6z"/>
                            <line style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} x1="13.6" y1="17.3" x2="13.6" y2="32.5" />
                        </g>
                        <path style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} d="M34.5,17.3c0,0,5.8,1,5.8,7.6s-5.8,7.6-5.8,7.6" />
                        <path style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} d="M34.5,9.7c0,0,11.6,2,11.5,15.2S34.5,40.1,34.5,40.1" />
                    </svg>
                )

            case 'soundoff':
                return (
                    <svg viewBox="0 0 50 50">
                        <line style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} x1="31.4" y1="19.1" x2="43.2" y2="30.7" />
                        <line style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} x1="43.1" y1="19.1" x2="31.6" y2="30.9" />
                        <g>
                            <path style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} d="M24,6c-2.8,2.6-10.4,11.2-10.4,11.2l-6.4-0.1c0,0-2.1-0.5-2.2,1.9c-0.1,2.4,0,5.8,0,5.8s-0.1,3.4,0,5.8
        c0.1,2.4,2.2,1.9,2.2,1.9l6.4-0.1c0,0,7.7,8.7,10.4,11.2c2.8,2.6,3.5-0.3,3.5-0.3V24.9V6.4C27.5,6.4,26.8,3.5,24,6z"/>
                            <line style={{ fill: 'none', stroke: '#E6007E', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }} x1="13.6" y1="17.3" x2="13.6" y2="32.5" />
                        </g>
                    </svg>
                )

            /*
            <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><defs>
            <style>.cls-1{fill:none;stroke:#e6007e;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title>Playbutton_1</title><circle class="cls-1" cx="22" cy="22" r="21"/><polygon class="cls-1" points="18.17 14.15 18.17 30.85 29.71 22.5 18.17 14.15"/></svg>
            */

        }

    }

    
    return (
        getIcon()
    )
}

export default Icon;