import React, { useRef, useMemo, useLayoutEffect, useEffect, useState } from 'react'

import styles from './Images.module.sass'
import { useIsVisivible } from 'hooks'
import LazyImageContainer from 'components/LazyImageContainer'


const Test = ({style, top, url}) => {
    const ref = useRef(null)
    const { loaded, visible } = useIsVisivible({ref})
    //console.log(top)

    const classes = ['fadeIn', 'fromBottom']
	visible && classes.push('show')

    return (<div ref={ref} className={classes.join(' ')} style={style}>
       <LazyImageContainer shouldLoad={loaded} url={url} asImg withFadeIn />
    </div>)
} 

export default function Images({ data, style }) {
    const component = useRef()
    //const html = useRef();

    let [ testY, setTestY ] = useState(0)

    useEffect(() => {
        if(component.current) {
            setTestY(component.current.getBoundingClientRect().y + window.scrollY)
            console.log('>>' + component.current.getBoundingClientRect().y + window.scrollY)
        }
    });
    
    const html = useMemo(
        () => {
            if (component.current) {

                const clientWidth = component.current.clientWidth
                const spaceVertical = clientWidth * 0.03
                //const spaceHorizontal = clientWidth * 0.067
                const spaceHorizontal = clientWidth * 0.03
                const groups = data.images
                const content = []
                let height

                const addY = component.current.getBoundingClientRect().y + window.scrollY
                console.log('render with: ' + addY)

                //const spaceVertical = 10
                //const spaceHorizontal = 10

                switch (data.layout) {
                    case 'cols':

                        const dimensions = groups.reduce((acc, group) => {

                            const spaceGroup = (group.length - 1) * spaceVertical
                            const height = 1 / group.reduce((acc, image) => (
                                acc + image.height / image.width
                            ), 0)

                            return {
                                height: acc.height + height,
                                width: acc.width + (spaceGroup * height)
                            }
                        }, { height: 0, width: 0 })

                        height = (clientWidth - (groups.length - 1) * spaceHorizontal + dimensions.width) / dimensions.height;

                        let left = 0

                        groups.forEach((group, groupIndex) => {

                            const groupHeight = group.reduce((acc, image) => (
                                acc + image.height / image.width
                            ), 0)

                            const groupWidth = (height - (group.length - 1) * spaceVertical) / groupHeight

                            let top = 0

                            group.forEach((image, index) => {

                                const width = groupWidth;
                                const height = image.height * groupWidth / image.width

                                const url = ( width > 900 || height > 900 ) ? image.thumbnailUrlTall : image.thumbnailUrl
                               
                                content.push(
                                    <Test key={image.ID} style={{
                                        position: 'absolute',
                                        width: `${width}px`,
                                        height: `${height}px`,
                                        left: `${left}px`,
                                        top: `${top}px`,
                                        transitionDelay: `${groupIndex*0.3}s`,
                                        backgroundColor: image.color
                                    }}
                                    top={top+addY}
                                    url={url}
                                    >

                                    </Test>
                                )

                                top += height + spaceVertical;
                            })

                            left += groupWidth + spaceHorizontal
                        })

                        break;

                    case 'rows':

                        let top = 0

                        groups.forEach((group, i) => {

                            const test = group.reduce((acc, image) => (

                                acc + image.width / image.height

                            ), 0)

                            console.log(test)

                            let left = 0

                            group.forEach((image, j) => {

                                const width = image.width / image.height / test * (clientWidth - (group.length - 1) * spaceHorizontal);
                                const height = width / image.width * image.height
                                const url = ( width > 900 || height > 900 ) ? image.thumbnailUrlTall : image.thumbnailUrl
                                
                                content.push(
                                    <Test key={image.ID} style={{
                                        position: 'absolute',
                                        width: `${width}px`,
                                        height: `${height}px`,
                                        left: `${left}px`,
                                        top: `${top}px`,
                                        transitionDelay: `${j*0.3}s`,
                                        backgroundColor: image.color
                                    }}
                                    top={top+addY}
                                    url={url}
                                    >

                                    </Test>
                                )

                                left += width + spaceHorizontal

                                if (j === group.length - 1) {
                                    top += height

                                    if (i < groups.length - 1) top += spaceVertical
                                }

                            })

                        })

                        height = top

                        break;
                }



                return (<div className="images" style={{ height: `${height}px` }}>
                    {content}
                </div>)
            }

        },
        [component.current, style.scale, testY]
    );


    return (
        <div className={'content ' + styles.images}>
            <div className="center main">
                <div>
                    {data.title &&
                        <h2 style={style.h2}>{data.title}</h2>
                    }
                    <div ref={component}>
                        {html}
                    </div>
                </div>
            </div>
        </div>
    )
}

//export default Images 