import React, { Component, useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from 'components/content/Breadcrumb'
import Loading from 'components/loading'
import Icon from 'components/icon/Icon'

import { ScaleSize } from 'Functions'

import Player from '@vimeo/player';
import styles from './Header.module.sass'

import LazyImageContainer from 'components/LazyImageContainer'

function HeaderBig({data, style, language}) {
	const [visible, setVisible] = useState(false)
	const [ currentImage, setCurrentImage ] = useState(0)
	const [ muted, setMuted ] = useState(true)

	const breadcrumb = data.breadcrumb;
	data = data.header

	useEffect(() => {
		setVisible(true)
	}, [])

	const media = useMemo(
		() => {
			if(data.contentType === 'video') {
				//return null
				
				return (
					<li>
						<iframe 
							id="iframe-vimeo"
							src={data.video}
							width="100%"
							height="100%"
							frameBorder="0"
							allow="autoplay; fullscreen">
						</iframe>
						<Loading language={language} />
					</li>
				)
			} else {
				const lastImage = currentImage > 0 ? currentImage-- : data.images.length-1
				const media = data.images.map((data, i) => {

					let style;

					if(i===currentImage) {
						style = {opacity: 1, zIndex: 3 }
					} else if(i===lastImage) {
						style = {opacity: 0, zIndex: 2 }
					}

					return <li key={i} style={{...style, backgroundColor: data.color}}><LazyImageContainer url={data.url} shouldLoad withFadeIn /></li>
				});

				return media
			}
		},
		[],
	);

	const toggleMute = () => {
		var options = {
			id: 375699574,
			background: true
		};

    	var player = new Player("iframe-vimeo", options);

    	if(muted) {
    		player.setVolume(1)
    	} else {
    		player.setVolume(0)
    	}

		setMuted(!muted)
    }
	
	let scale = style.scale
	style = {...style,
		h1: {
			fontSize: ScaleSize(70, 21, scale),
			lineHeight: ScaleSize(92, 42, scale)
		},
		h1Small: {
			fontSize: ScaleSize(56, 21, scale),
			lineHeight: ScaleSize(73, 42, scale)
		},
		p: {
			fontSize: ScaleSize(34, 18, scale),
			lineHeight: ScaleSize(49, 26, scale)
		},
		iconBig: {
			width: ScaleSize(106, 43, scale),
		}
	}

	let classNames = [styles.header, data.type, 'fadeIn']
	visible && classNames.push('show')
	data.hideBreadcrumb && classNames.push('hideBreadcrumb')

	let styleHeader = {}

	if(data.type === 'big') {

		if(data.contentType === 'video') {
			styleHeader = {paddingBottom: `${data.video_height / data.video_width * 100}%`}
		} else {

			const image = data.images[currentImage]
			styleHeader = {paddingBottom: `${image['height'] / image['width'] * 100}%`}
		} 
	}
	

	return (
		<div className={classNames.join(' ')}>
			<div style={{background: data.color}}>
				<div>
					<div className="center main">
						<div>
							<div className={'media ' + data.contentType} style={styleHeader}>
								<ul>
									{media}
								</ul>
								{data.target &&
									<Link to={data.target} className="area">
										<span className="icon main selected middle" style={{width: style.iconBig.width, height: style.iconBig.width}}>
											<Icon type="arrow" />
										</span>
									</Link>
								}
								{data.contentType === 'video' &&
									<span className="mute" style={{width: style.iconSound.width, height: style.iconSound.width}} onClick={toggleMute}>
										<Icon type={muted ? 'soundon' : 'soundoff'} />
									</span>
								}
								{data.type === 'standard' &&
									<h1 className={'textfadeIn fromBottom ' + (data.target ? 'target' : '')} style={{marginTop: 92*scale > 42 ? `-${92*scale}px` : '-42px', ...style.h1}}>
										<span dangerouslySetInnerHTML={{__html: data.title}} />
									</h1>
								}
							</div>
						</div>
					</div>
				</div>
				{data.type === 'big' &&
					<div className="text">
						<div className="center main">
							<div>
								<h1 className={'textfadeIn fromBottom ' + (data.target ? 'target' : '')} style={{marginTop: 92*scale > 42 ? `-${92*scale}px` : '-42px', ...style.h1}}>
									<span dangerouslySetInnerHTML={{__html: data.title}} />
								</h1>
								<div className="quote textfadeIn fromBottom delay" >
									<p dangerouslySetInnerHTML={{__html: data.text}} style={style.p} />
									<span style={{width: style.quote.width, height: style.quote.width}} />
									<span style={{width: style.quote.width, height: style.quote.width}} />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
			{!data.hideBreadcrumb &&
				<Breadcrumb data={breadcrumb} />
			}
		</div>
	)
}


class HeaderBig2 extends Component {
	constructor() {
		super()

        this.state = {
            currentImage: 0
        }  
    }

	componentDidMount = () => {
		let data = this.props.data.header

        if(data.contentType !== 'video' && data.images && data.images.length>1) {
        	this.interval = setInterval(this.startAutoSlide, 3000);
        }
    }

    componentWillUnmount = () => {
    	if(this.interval) {
    		clearInterval(this.interval);
    	}
    }

    startAutoSlide = () => {
    	let data = this.props.data.header

    	let currentImage = this.state.currentImage;

    	currentImage++;

    	if(currentImage >= data.images.length) {
    		currentImage = 0
    	}

    

    	this.setState({
    		lastImage: this.state.currentImage,    
            currentImage: currentImage
        }); 
    }

    mute = () => {
    	var player = new Player("iframe-vimeo");

    	if(!this.state.sound) {
    		player.setVolume(1)
    	} else {
    		player.setVolume(0)
    	}

    	this.setState({
            sound: !this.state.sound
        })
    	
    }

	render() {
		let data = this.props.data.header,
		scale = this.props.style.scale,
		media

		if(data.contentType !== 'video' && data.images) {
			media = data.images.map((data, idx) => {

				let style;

				if(idx===this.state.currentImage) {
					style = {opacity: 1, zIndex: 3 }
				} else if(idx===this.state.lastImage) {
					style = {opacity: 0, zIndex: 2 }
				}

				return <li key={idx} style={{...style, backgroundImage: `url(${data})`}}></li>
			});
		}

		if(data.contentType === 'video') {
			media = <li><iframe 
							id="iframe-vimeo"
							src={data.video}
							width="113%"
							height="113%"
							frameBorder="0"
							allow="autoplay; fullscreen">
					</iframe>
					<Loading />
					</li>
		}

		let style = {...this.props.style,
			h1: {
				fontSize: ScaleSize(70, 21, scale),
				lineHeight: ScaleSize(92, 42, scale)
			},
			h1Small: {
				fontSize: ScaleSize(56, 21, scale),
				lineHeight: ScaleSize(73, 42, scale)
			},
			p: {
				fontSize: ScaleSize(34, 18, scale),
				lineHeight: ScaleSize(49, 26, scale)
			},
			iconBig: {
                width: ScaleSize(106, 43, scale),
            },
            iconSound: {
                width: ScaleSize(46, 25, scale)
            }
		}

		//console.log(style)

		let classNames = [data.type]
		data.hideBreadcrumb && classNames.push('hideBreadcrumb')

		if(data.contentType === 'video') classNames.push('video')

		return (
			<div className={'header main ' + classNames.join(' ')}>
				<div style={{background: data.color}}>
					<div>
						<div className="center main">
							<div>
								<div className="media">
									<ul>
										{media}
									</ul>
									{data.target &&
										<Link to={data.target} className="area">
											<span className="icon main selected middle" style={{width: style.iconBig.width, height: style.iconBig.width}}>
												<Icon type="arrow" />
											</span>
										</Link>
									}
									{data.contentType === 'video' &&
										<span className="mute" style={{width: style.iconSound.width, height: style.iconSound.width}} onClick={this.mute}>
											<Icon type={this.state.sound ? 'soundon' : 'soundoff'} />
										</span>
									}
								</div>
							</div>
						</div>
					</div>
					{data.type === 'big' &&
						<div className="text">
							<div className="center main">
								<div>
									<h1 className={data.target ? 'target' : null} style={{marginTop: 92*scale > 42 ? `-${92*scale}px` : '-42px', ...style.h1}}>
										<span dangerouslySetInnerHTML={{__html: data.title}} />
									</h1>
									<div className="quote">
										<p dangerouslySetInnerHTML={{__html: data.text}} style={style.p} />
										<span style={{width: style.quote.width, height: style.quote.width}} />
										<span style={{width: style.quote.width, height: style.quote.width}} />
									</div>
								</div>
							</div>
						</div>
					}
				</div>
				{!data.hideBreadcrumb &&
					<Breadcrumb data={this.props.data.breadcrumb} />
				}
				{data.type === 'standard' &&
					<div className="text">
						<div className="center main">
							<div>
								<h1 style={style.h1Small}><span dangerouslySetInnerHTML={{__html: data.title}} /></h1>
								{(data.text || this.props.headerText) &&
									<p style={style.p} dangerouslySetInnerHTML={{__html: this.props.headerText ? this.props.headerText : data.text}} />				
								}
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default HeaderBig;