import  { useState, useEffect, useLayoutEffect } from 'react'

function useIsVisivible({ref, top}) {
	const [visible, setVisible] = useState(false)
	const [loaded, setLoaded] = useState(false)
	
	/*
	useEffect(() => {
	
	}, [ref, top])
	*/
	useLayoutEffect(() => {
		//if (ref.current) {
			isVisible()
			window.addEventListener('scroll', isVisible)
		//}
			
		return () => {
			window.removeEventListener('scroll', isVisible)
        }
	})
	

	const isVisible = () => {
		if(!ref && !top) return 
		if(ref && !ref.current) return

		const scrollTop = window.scrollY || document.documentElement.scrollTop
		const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
		const elementY = ref.current.getBoundingClientRect().y || ref.current.getBoundingClientRect().top

		//console.log(scrollTop, height)
		if(!top) top = elementY + scrollTop
		console.log(scrollTop+height-400, '>', top)

		if((scrollTop+height+400)>top) { 
			setLoaded(true)
			//window.removeEventListener('scroll', isVisible)
		}	else {
			console.log('load not')
		}

		if((scrollTop+height-200)>top) { 
			setVisible(true)
			window.removeEventListener('scroll', isVisible)
		}	
	}

	function debounce(func, delay) {

		let timeout = null;
		return function() {
		  window.clearTimeout(timeout);
		  timeout = window.setTimeout(function() {
			func();
		  }, delay);
		};
	}

	return { loaded, visible }
}

export {
    useIsVisivible
}