import React, {useRef} from 'react';
import styles from './Teaser.module.sass'
import { withRouter, Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { ScaleSize } from 'Functions'
import { useIsVisivible } from 'hooks'

const Index = ({style, data}) => {
    const ref = useRef()
	const { loaded, visible } = useIsVisivible({ref})

    console.log(style)

    const classes = ['content', styles.teaser]
	visible && classes.push('test')

    return (
        <div className={classes.join(' ')} ref={ref} >       
            <div className="center main">
                <div className="inner">
                    <div class="flex">
                        {data.content.map((teaser, i) => (
                            <div className="col">
                                <Link to={teaser.target} className="area" />
                                <div className="image" style={{backgroundImage: `url(${teaser.image})`}} />
                                <div className="text">
                                    <h2 style={style.h2}><span dangerouslySetInnerHTML={{__html: teaser.title}}></span></h2>
                                    <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                                        <Icon type="arrow" />
                                    </span>
                                </div>
                            </div>
                        ))} 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Index);