import React from 'react';
import styles from './Contact.module.sass'
import { withRouter, Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { RichTextClick, ScaleSize } from 'Functions'

const contactTeaser = ({style, location, history, data}) => {

    let scale = style.scale

    style = {...style,
		p: {
			fontSize: ScaleSize(30, 18, scale),
			lineHeight: ScaleSize(36, 26, scale)
		}
	}

    return (
        <div className={'content ' + styles.contact}> 
            <div className="test">
                <a className="area" href={`${data.target}`}></a>      
                <div className="center main">
                    <div className="inner">
                        <p style={style.p} onClick={(e) => RichTextClick(e, location, history)}>{data.text}
                            <span className="icon main" style={{width: style.icon.width, height: style.icon.width}}>
                                <Icon type="arrow" />
                            </span>
                        </p>
                        <span />
                    </div>
                </div>          
            </div>
        </div>
    );
};

export default withRouter(contactTeaser);