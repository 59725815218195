import React, { useState, useRef } from 'react';
import styles from './Media.module.sass'
import { withRouter, Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'
import { ScaleSize } from 'Functions'

import Player from '@vimeo/player';

const Media = ({data, style}) => {
    const [ playing, setPlaying ] = useState(false)
    const [ fullscreen, setFullscreen ] = useState(false)
    const [ muted, setMuted ] = useState(true)

    const video = useRef(false)

    let iframeId = useRef( Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) ) 

    let media
    let showMuteBtn  

    const playVideo = () => {
        video.current.play();
        setPlaying(true)
    }

    const toggleMute = () => {

    	var player = new Player(iframeId.current);

    	if(muted) {
    		player.setVolume(1)
    	} else {
    		player.setVolume(0)
    	}

		setMuted(!muted)
    }

    switch(data.mediaType) {
        case 'image':

            media = <div className={'image' + (fullscreen ? ' fullscreen' : '')} style={{ backgroundImage: `url(${data.url})` }}>
                {fullscreen &&
                    <span className="close" onClick={() => setFullscreen(false)}></span>
                }
            </div>

            break;

        case 'iframe':

            if(data.url.indexOf('vimeo.com')!==-1 && data.url.indexOf('background=1')!==-1) showMuteBtn = true

            media = <div className={'iframe' + (fullscreen ? ' fullscreen' : '')}>
                {fullscreen &&
                    <span className="close" onClick={() => setFullscreen(false)}></span>
                }
                <iframe src={data.url} allow="autoplay; fullscreen" id={iframeId.current}></iframe>
                {showMuteBtn &&
                    <span className="mute" style={{width: style.iconSound.width, height: style.iconSound.width}} onClick={toggleMute}>
                        <Icon type={muted ? 'soundon' : 'soundoff'} />
                    </span>
                }
            </div>

             

            break;

        case 'video':

            media = <div className={fullscreen ? 'fullscreen' : ''}>
                {fullscreen &&
                    <span className="close" onClick={() => setFullscreen(false)}></span>
                }
                {!playing && 
                    <div className="play" onClick={() => playVideo()}>
                        <span 
                            className="icon main lens middle aniHover"
                            style={{width: style.icon.width, height: style.icon.width}}
                            ><Icon type="play" /></span>
                    </div>
                }
                <video id="video1" controls={playing ? true : false} ref={video}>
                    <source src={data.url} type="video/mp4" />                   
                </video>
            </div>

            break;
    }

    let stylesTest = {}
    
    if(data.color) stylesTest = {background: data.color}

    return (
        <div className={'content ' + styles.media} style={stylesTest}> 
            <div className="center main">
                <div className="inner">
                    <div className="title clear">
                        {data.title &&
                            <h2 style={style.h2}>{data.title}</h2>
                        }
                        {data.fullscreen &&
                            <span className="fullscreen" onClick={() => setFullscreen(true)}>Fullscreen</span>
                        }
                    </div>
                    <div className="media" style={{paddingBottom: `${data['height'] / data['width'] * 100}%`  }}>
                        {media}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Media);