import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '../icon/Icon'
//import ContentStoreActions from '../../actions/ContentStoreActions'

import { ParamAdd, ScaleSize, RichTextClick } from '../../Functions'

class TextImage extends Component {
	static contextTypes = {
        router: PropTypes.object
    }

	clickImage = () => {
		let data = this.props.data

		if(data.images.length) {

			//ContentStoreActions.setGallery(data.images);

			let image = data.images[0];

			this.context.router.history.push( ParamAdd(this.props.location, 'gallery=' + image.ID))

		} else {

			let image = {
				ID: data.ID,
				iframe_url: data.iframe_url
			}

			//ContentStoreActions.setGallery(Array(image));
			this.context.router.history.push( ParamAdd(this.props.location, 'gallery=' + image.ID))
		 
		}
	}


	render() {

		let data = this.props.data,
		scale = this.props.style.scale,
		style = this.props.style

		let image = null

		if(data.image) {

			let icon = data.images.length ? 'lens' : data.iframe_url ? 'play' : null 

			let overlay = data.iframe_url || data.images.length ? true : false 

			image = <div className="col image">
				<span style={{background: data.color}} />
				<div>
					<div 
						className="image"
						style={{backgroundImage: `url(${data.image})`, paddingBottom: `${data.height/data.width*100}%` }} 
						onClick={overlay ? this.clickImage : null}
						>
						{overlay &&
							<span className="hoverArea aniHover" />
						}
						{overlay &&
							<span 
								className="icon main lens middle aniHover"
								style={{width: style.icon.width, height: style.icon.width}}
								><Icon type={icon} /></span>
						}
					</div>
				</div>
				
			</div>
		}
		
		/*
		if(data.iframe) {
			image = <div className="col image">
				<span style={{background: data.color}} />
				<div dangerouslySetInnerHTML={{__html: data.iframe}}></div>
			</div>
		}
		*/

		let text = <div className="col text rich" style={style.rich} onClick={(e) => RichTextClick(e, this.props.location, this.props.history)}>
			<span style={{background: data.color}} />
			<div dangerouslySetInnerHTML={{__html: data.text}}></div>
		</div>

		return (
			<div className={'content textImage ' + data.imagePosition}>
				<div className="center main">
					<div className="inner clear">
						{data.imagePosition === 'left' ? (
					    	image
					    ) : (
					        text
					    )}
					    {data.imagePosition === 'left' ? (
					    	text
					    ) : (
					        image
					    )}
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(TextImage);